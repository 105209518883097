import React from 'react';
import Reveal from 'react-reveal/Reveal';
import styled from 'styled-components';

import Section from '../../Atoms/Section';
import Container from '../../Atoms/Container';
import Column from '../../Atoms/Column';
import Heading from '../../Atoms/Heading';
import Box from '../../Atoms/Box';
import Row from '../../Atoms/Row';
import FlexBox from '../../Atoms/FlexBox';

import RevealParagraph from '../../Atoms/RevealParagraph';

const StyledContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

function TextTextSideBySide(props) {
  const {
    title, desc, img, children, maxHeight, ...rest
  } = props;

  return (
    <Section
      height={['auto', 'auto', 'auto', '100vh']}
      position="relative"
      maxHeight={maxHeight || 1500}
      minHeight={800}
      {...rest}
    >
      <StyledContainer height="100%" fluid>
        <Row multi>
          <Column col="is-12 is-half-desktop" pr={[0, 0, 0, 7]}>
            <FlexBox width="100%" height="100%" pr={[2, 2, 5, 0]} pl={[2, 2, 5, 0]} justifyContent="flex-start" flexDirection="column">
              <Box>
                {title && (
                  <Reveal duration={1000} effect="section-title-ani">
                    <Heading h={1} pb={[4, 4, 5]} width="100%">
                      {title}
                    </Heading>
                  </Reveal>
                )}
                <RevealParagraph desc={desc} />
              </Box>
            </FlexBox>
          </Column>
          <Column col="is-12 is-half-desktop">{children}</Column>
        </Row>
      </StyledContainer>
    </Section>
  );
}

export default TextTextSideBySide;
