import React, { Component } from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { Formik } from 'formik';
import styled from 'styled-components';
import Dropzone from 'react-dropzone';

import Paragraph from '../Atoms/Paragraph';
import { makeRequest } from '../../utilis/helpers';
import Button from '../Atoms/Button';
import theme from '../../styles/theme';
import FlexBox from '../Atoms/FlexBox';
import Box from '../Atoms/Box';

import Upload from '../../assets/icons/upload.svg';
import FormInput from '../Form/FormInput';

const StyledForm = styled.form`
  width: 100%;
`;

class CareerForm extends Component {
  commonError = null;

  onDropAccepted = (setErrors, setFieldValue) => (acceptedFiles) => {
    // Do something with files
    setErrors({});
    setFieldValue('resume', acceptedFiles[0]);
  };

  onDropRejected = (setErrors, setFieldValue) => (rejectedFiles) => {
    // Do something with files
    console.log('rejected');
    setErrors({});
    setFieldValue('resume', null);
  };

  submitForm = async (values, actions) => {
    const form = new FormData();
    form.append('fullname', values.fullname);
    form.append('email', values.email);
    form.append('tel', values.tel);
    form.append('position', values.position);
    form.append('resume', values.resume);
    form.append('message', values.message);
    form.append('formType', 'career');

    try {
      const res = await makeRequest({
        data: form,
      });
      actions.setSubmitting(false);
      actions.setStatus({ submitted: 'Thank you for your enquiry. We will reply you as soon as possible.' });
    } catch (e) {
      actions.setSubmitting(false);
      actions.setErrors({ common: 'An error occurs. Please try again later.' });
    }
  };

  render() {
    const { intl } = this.props;

    const messages = defineMessages({
      formRequired: {
        id: 'career.form.required',
        defaultMessage: 'This field is required',
      },
      name: {
        id: 'career.form.name',
        defaultMessage: 'Full Name',
      },
      email: {
        id: 'career.form.email',
        defaultMessage: 'Email Address',
      },
      tel: {
        id: 'career.form.tel',
        defaultMessage: 'Phone',
      },
      position: {
        id: 'career.form.position',
        defaultMessage: 'Position',
      },
      resume: {
        id: 'career.form.resume',
        defaultMessage: 'Upload Resume',
      },
      message: {
        id: 'career.form.message',
        defaultMessage: 'Tell us something about you',
      },
    });


    return (
      <Formik
        validateOnBlur={false}
        validateOnChange={false}
        initialValues={{
          fullname: '',
          email: '',
          tel: '',
          position: '',
          resume: null,
          message: '',
        }}
        validate={(values, props) => {
          this.commonError = null;
          const errors = {};
          if (!values.fullname) {
            errors.fullname = intl.formatMessage(messages.formRequired);
          }
          if (!values.email) {
            errors.email = intl.formatMessage(messages.formRequired);
          }
          if (!values.tel) {
            errors.tel = intl.formatMessage(messages.formRequired);
          }
          if (!values.position) {
            errors.position = intl.formatMessage(messages.formRequired);
          }
          if (!values.resume) {
            errors.resume = intl.formatMessage(messages.formRequired);
          }
          return errors;
        }}
        onSubmit={async (values, actions) => {
          this.submitForm(values, actions);
        }}
        render={({
          values,
          errors,
          status,
          touched,
          handleSubmit,
          setStatus,
          setFieldValue,
          isSubmitting,
          isValid,
          setSubmitting,
          ...actions
        }) => (
          <FlexBox width="100%" height="100%" px={[2, 2, 5, 3]}>
            <StyledForm onSubmit={handleSubmit}>
              <FormInput
                name="fullname"
                type="text"
                placeholder={intl.formatMessage(messages.name)}
                errors={errors}
                {...actions}
              />
              <FormInput
                name="email"
                type="email"
                placeholder={intl.formatMessage(messages.email)}
                errors={errors}
                {...actions}
              />
              <FormInput
                name="tel"
                type="tel"
                placeholder={intl.formatMessage(messages.tel)}
                errors={errors}
                {...actions}
              />
              <FormInput
                name="position"
                type="text"
                placeholder={intl.formatMessage(messages.position)}
                errors={errors}
                value={values.position}
                {...actions}
              />
              <Dropzone
                multiple={false}
                accept="image/jpeg, image/png, application/pdf"
                ref={this.zoneRef}
                onDrop={this.onDrop}
                onDropAccepted={this.onDropAccepted(actions.setErrors, setFieldValue)}
                onDropRejected={this.onDropRejected(actions.setErrors, setFieldValue)}
              >
                {({
                  getRootProps,
                  getInputProps,
                  isDragActive,
                  isDragAccept,
                  isDragReject,
                  isFocused,
                  open,
                  ...rest
                }) => (
                  <div className="field" style={{ marginBottom: '28px' }}>
                    <div className="file has-name is-fullwidth is-right">
                      <label className="file-label">
                        <input
                          className="file-input is-danger"
                          type="file"
                          name="resume"
                          {...getInputProps()}
                        />

                        <span className="file-name" style={{ lineHeight: 4, display: 'flex', marginBottom: 0 }}>
                          {values.resume ? values.resume.name : <span style={{ color: '#73727d' }}>{intl.formatMessage(messages.resume)}</span>}
                          <Box as="span" height="100%" width={32} ml="auto" mr={2}>
                            <Upload />
                          </Box>
                        </span>
                      </label>
                    </div>
                    {errors.resume && <p className="help is-danger">{errors.resume}</p>}
                  </div>
                )}
              </Dropzone>
              <FormInput
                name="message"
                type="textarea"
                placeholder={intl.formatMessage(messages.message)}
                errors={errors}
                {...actions}
              />
              {status && status.submitted && (
                <Paragraph fontSize={1} color={theme.color.gold}>
                  {status.submitted}
                </Paragraph>
              )}
              {errors.common && (
                <Paragraph fontSize={1} color={theme.color.red}>
                  {errors.common}
                </Paragraph>
              )}
              <Button
                width={160}
                primary
                type="submit"
                text="submit"
                loading={isSubmitting}
                disabled={isSubmitting || !isValid}
              />
            </StyledForm>
          </FlexBox>
        )}
      />
    );
  }
}

export default injectIntl(CareerForm);
