import React from 'react';
import get from 'lodash/get';

import { graphql } from 'gatsby';
import TextTextSideBySide from '../../components/SectionBlocks/RepeatableSections/TextTextSideBySide';
import CareerForm from '../../components/Career/CareerForm';

function Index({ data, cmsData = {} }) {
  const detail = get(data, 'allMarkdownRemark.edges.0.node.frontmatter', cmsData);

  return (
    <TextTextSideBySide
      title={detail.title}
      desc={detail.desc}
      pt={[8, 8, 160, 0]}
      pb={[8, 8, 120, 0]}
    >
      <CareerForm positions={detail.positions} />
    </TextTextSideBySide>
  );
}

export default Index;

export const pageQuery = graphql`
  query CareerPage {
    allMarkdownRemark(filter: { frontmatter: { component: { eq: "career" } } }, limit: 1) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            desc
            positions {
              placeholder
              list {
                label
                value
              }
            }
          }
        }
      }
    }
  }
`;
